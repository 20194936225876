<template>
  <div class="actions">
    <BrandButton
      :class="routes.bills != activeRoute ? 'notActive' : 'active'"
      :target="routes.bills"
      as="router-link"
    >
      <VueText sizeLevel="4" weightLevel="4">FATURALARIM</VueText>
    </BrandButton>
    <BrandButton
      :class="routes.eArchive != activeRoute ? 'notActive' : 'active'"
      :target="routes.eArchive"
      as="router-link"
    >
      <VueText sizeLevel="4" weightLevel="4">E-ARŞİV</VueText>
    </BrandButton>
  </div>
</template>
<script>
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import { ICON_VARIABLES, COMPONENT_SIZES } from '@/constants/component.constants';

export default {
  name: 'BrandInvoiceToolBar',
  props: {
    routes: {
      type: Object,
      required: true,
    },
    activeRoute: {
      type: String,
      required: true,
    },
  },
  components: {
    BrandButton,
    VueText,
  },
  computed: {
    icon() {
      return {
        sheetLines: ICON_VARIABLES.sheetLines,
        speechBubble: ICON_VARIABLES.speechBubble,
      };
    },
    sizes() {
      return COMPONENT_SIZES;
    },
  },
};
</script>
<style scoped lang="scss">
.actions {
  display: flex;
  z-index: 5;
  a {
    width: inherit !important;
    background: palette-color(white-100);
    padding: 0;
    margin-right: 30px;
  }
  .notActive {
    position: relative;
    span {
      p {
        color: palette-color(grey-30);
      }
    }
  }
  .active {
    position: relative;
    z-index: 2;
    span {
      p {
        color: palette-color(grey-40);
      }
    }
    &:after {
      content: '';
      width: 100%;
      height: 2px;
      position: absolute;
      z-index: 3;
      bottom: 0;
      background-image: linear-gradient(271deg, #d20051, #ffa300);
    }
  }
}
</style>
